<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("supervisors") }}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addSupervisor">
          <sui-icon name="add"  /> {{ $t('addSupervisor') }}
        </button>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-4">
                <input type="text" class="form-control" v-model="key" :placeholder="this.$t('supervisorSearch')" />
              </div>
              <div class="col-sm-2">
                <button type="submit" class="btn btn-primary mL-10">
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                  <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("mobileNumber") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("employeeType") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 100px;"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("status") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 100px;"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("edit") }}
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in supervisors" :key="item.id">
                <tr role="row" class="odd">
                  <td class="sorting_1">{{ item.id }}</td>
                  <td>
                    {{getTranslation(item.ar_name,item.en_name) }}
                  </td>
                  <td style="direction: ltr;">
                    {{ item.user.credential }}
                  </td>
                  <td>
                    {{ item.employee_type_id == 2? $t('owner') : $t('supervisor') }}
                  </td>
                  <td v-if="item.user.status_id == 1" @click="changeStatus(item)">
                  <a href="javascript:"
                    ><span
                      class="
                        badge
                        bgc-green-50
                        c-green-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      >{{ $t("active") }}</span
                    ></a
                  >
                </td>
                <td v-else @click="changeStatus(item)">
                  <a href="javascript:"
                    ><span
                      class="
                        badge badge-pill
                        bgc-red-50
                        c-red-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      >{{ $t("disactive") }}</span
                    ></a
                  >
                </td>
                  <td>
                  <button type="button" @click="editSupervisor(item)" class="btn btn-primary">{{ $t('edit') }}</button>
                </td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!supervisors.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
              <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 0 " :per-page="40" @paginate="getSupervisors"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        statusLoading: true,
        key: "",
        supervisors: [],
        pagination: {},
        branches: [],
        page:1,
      };
    },
    created() {
      this.getSupervisors();
    },
    methods: {
      getRole() {
      return sessionStorage.getItem('role');
    },
      getTranslation(ar , en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function (e) {
        e.preventDefault();
        this.getSupervisors();
      },
      getSupervisors: function () {
        this.loading = true;
        this.$http
          .get("/backend/supervisors/get", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              key : this.key,
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.supervisors = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      changeStatus: function (item) {
      this.statusLoading = true;
      item.user.status_id = item.user.status_id == 1 ? 2 : 1;

      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .patch(
          "/backend/supervisors/changeSupervisorStatus",
          {
            status_id: item.user.status_id,
            supervisor_id: item.user.id,
          },
          { headers }
        )

        .then(
          function (data) {
            this.statusLoading = false;
            switch (data.status) {
              case 200:
                this.$emit("closePanel", {});
                this.success(this.$t("operationStatusSuccess"));
                break;
            }
          },
          (err) => {
            item.user.status_id = item.user.status_id == 1 ? 2 : 1;
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("operationStatusFailed"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.loading = false;
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
      addSupervisor: function () {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/supervisors/AddSupervisor.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload:this.getSupervisors,
          },
        });
      },
      editSupervisor(supervisor) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/supervisors/EditSupervisor.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            supervisor: supervisor,
            reload:this.getSupervisors,
          },
        });
  
        panel1Handle.promise.then((result) => {});
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>